@import "../../Asset/variable.scss";

.search input {
  background-color: white;
  padding-top: 20%;
  margin-left: "200px";
  border: 0;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 18px;
  pointer-events: auto;
  overflow-x: auto;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.fontstyle {
  color: #2d2d2d;
  font-weight: bold;
  // border-radius: 50%;
}

.dataResult1::-webkit-scrollbar {
  scroll-behavior: unset;
}

.dataResult .dataItem {
  padding: 7px;
  // padding-right: 20px;
  position: relative;
  width: 100%;
  height: 40px;
  display: flex;
  // justify-content: flex-end;
  align-items: center;
  color: black;
  box-shadow: inset 0 0 0 4px #f6f8fb;
}

.tripSelectIcon {
  width: 23px;
  height: 23px;
  cursor: pointer;
}

#clearBtn {
  cursor: pointer;
}

.mainWrapper {
  position: relative;
  margin-top: 14px;
  margin-right: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  background-color: #f6f8fb;
  border-radius: 20px;
  align-items: center;
  z-index: 500;
}

.iconFilter {
  width: 1em;
  height: 1em;
  position: absolute;
  left: 22.5em;
  top: 2em;
}

.button {
  cursor: pointer;
}

.SearchIcon {
  position: absolute;
  padding-left: 5px;
  padding-top: 1vh;
  // overflow: hidden;
  margin-top: 2;
  overflow-x: auto;
  z-index: 500;
}

.title {
  font-size: 12px;
  padding: 5px;
  margin-left: 16px;
  // margin-top: 5.5em;
  color: #004a5d;
  position: relative;
  width: 85%;
  height: 20px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1000;
}

.title2 {
  font-size: 12px;
  padding: 5px;
  margin-left: 16px;
  color: #004a5d;
  position: relative;
  width: 85%;
  height: 15px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1000;
}

.test {
  padding: 1rem;
  border: 1px solid #ddd;
}

.searchIcon2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 5px 10px 5px;
  background-color: $backgroundBlue;
  margin-bottom: 10px;

  border-radius: 5px;

  & > p {
    font-size: 14px !important;
    margin-bottom: 5px !important;
  }

  & > .details {
    flex: 0.95;
    display: flex;
    flex-direction: row;

    & > .left {
      margin-right: 5px;
    }
  }

  & > .right {
    flex: 0.05;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    & > svg {
      cursor: pointer;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  padding: 5px 0px 5px 0px;
}

.vehicleIcon {
  width: 20px;
}

.divider {
  width: 5px;
}

.arrowIcon {
  margin: 0px;
}

.hiddenDiv {
  display: none;
}

.calendarIcon {
  z-index: 100;
  color: goldenrod;
  align-content: center;
  margin: 10px 0px -20px 7px;
  font-size: 0.6rem;
  font-weight: bolder;
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.border {
  border: 2px solid black;
  padding: 20px;
  text-align: center;
}
.searchImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}
.messageText {
  text-align: center;
  margin-left: 1vh;
  padding: 5em;
}
